import React, { useEffect, useLayoutEffect } from "react";

const Form = ({ onSubmit }) => {

    useLayoutEffect(() => {
        window.hbspt.forms.create({
            region: "na1",
            portalId: "9226978",
            formId: "c58eb1a8-9e08-46b9-9503-19d2c5342bbc",
            target: "#hbform",
        
        })
    }, [])

    return (
        <div className='flex-grow-1 flex flex-col flex-center max-600 pt20 pb20 '>
            <div className="flex flex-col flex-grow-1 flex-center max-h-200   ">
                <h5 className="">Register to reveal more and receive exclusive access to our Tapestry brochure</h5>
            </div>

            <div id='hbform' className=' contact--form small'>


            </div>

        </div>
    )
}


export default Form