import React from 'react';
import gsap from 'gsap';
import TransitionLink from 'gatsby-plugin-transition-link'

const Link = ({ children,to,...props }) => {
    return (
        <TransitionLink
            {...props}
            to={to}
            exit={{
                trigger: ({ exit, node }) => {
                   gsap.to(node,{opacity:0,duration:1,ease:`Power2.easeOut`})
                },
                length: 1
            }}
            entry={{
                trigger: ({ exit, node }) => {
                    gsap.fromTo(node,{opacity:0},{opacity:1,duration:1,ease:`Power2.easeOut`})
                 },
                delay: 1
            }}
        >
           {children}
        </TransitionLink>

    )
}

export default Link