import React, { useEffect, useState, useRef } from "react"
import Spacer from "./spacer"
import ContactDetails from "./contactDetails"
import Form from "./form"
import { graphql, useStaticQuery } from "gatsby"
import logo from "../images/blackLogo.svg"
import Link from "./transitionLink"

const EnquirePanel = () => {
	const timer = useRef(null)
	const numberOfTimesRun = useRef(0)

	const hasAlreadySubmitted = () => {
		return localStorage.getItem("submitted")
	}

	const isContactOpened = () => {
		return document.querySelector(".enquire--background").classList.contains("is-open")
	}

	useEffect(() => {
		timer.current = setTimeout(() => {
			forceOpenContact()
		}, 20_000)

		return () => clearTimeout(timer)
	}, [])

	function forceOpenContact() {
		console.log(!hasAlreadySubmitted(), isContactOpened())
		if (!hasAlreadySubmitted()) {
			if (!isContactOpened() && numberOfTimesRun.current < 20) {
				numberOfTimesRun.current++
				openMenu()
			}
		}
	}

	const closeMenu = () => {
		document.querySelector(".enquire--panel").classList.remove("is-open")
		document.querySelector(".enquire--background").classList.remove("is-open")

		timer.current = setTimeout(() => {
			forceOpenContact()
		}, 60_000)
	}

	useEffect(() => {
		document.querySelector(".enquire--button").addEventListener("click", openMenu)
		document.querySelector(".enquire--background").addEventListener("click", closeMenu)
	}, [])

	const openMenu = e => {
		document.querySelector(".enquire--panel").classList.add("is-open")
		document.querySelector(".enquire--background").classList.add("is-open")
	}

	const data = useStaticQuery(graphql`
		query ContatcInfo {
			datoCmsTapestryLandingPage {
				email
				instagram
				facebook
				people {
					email
					name
					phone
				}
			}
		}
	`)

	const page = data.datoCmsTapestryLandingPage

	const contactInfo = { email: page.email, insta: page.instagram, facebook: page.facebook }
	const people = page.people

	return (
		<>
			<div className={"enquire--panel text-dark  flex "}>
				<div className='p40 w-100 rel enquiry--inner'>
					<div className='h-100 flex flex-col rel contact--wrapper'>
						<div className='flex small uppercase m-bb1 m-pb10'>
							<Link to='/' className=' m-show b0 '>
								<img width='100' src={logo} alt='Tapestry Logo' />
							</Link>
							<p className='m0 m-hide'>Enquire</p>
							<div className='mla'>
								<button className='uppercase close--button' onClick={closeMenu}>
									Close
								</button>
							</div>
						</div>
						<p className='small uppercase m-show m-mt40 m-mb0'>ENQUIRE </p>
						<Spacer />
						<Form />
						<Spacer />
						<ContactDetails contactDetails={contactInfo} salesAgent={people} />
					</div>
				</div>
			</div>
			<div className='enquire--background'></div>
		</>
	)
}

export default EnquirePanel
