import React from "react";

const ContactDetails = ({ salesAgent, contactDetails }) => {

    return (
        <>
            <p className="italic mb20 m-hide">For more information:</p>
            <div className="flex m-wrap mb40 m-mt40">

                <div className='mr40 m-100'>
                    <div> Siera Property Group</div>
                    <div> tapestry@sieragroup.com.au</div>
                    <div> 1300 474 372</div>

                </div>

            </div>
            <div className="flex small m-pb120 pb20">
                <a rel="noreferrer" href='https://www.instagram.com/sieragroup/' target='_blank' className='mr10 link ul-link b-black'>INSTAGRAM</a>
                <a rel="noreferrer" href={contactDetails?.facebook} target='_blank' className="link ul-link b-black">FACEBOOK</a>
            </div>
        </>
    )

}
export default ContactDetails   