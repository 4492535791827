/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { Helmet } from "react-helmet"
import * as React from "react"
import Scrolling from "../components/scrolling"
import EnquirePanel from "../components/enquire"
import "../scss/site.scss"

const Layout = ({ children, ...props }) => {
  return (
    <>
    <Helmet>
    <meta name="facebook-domain-verification" content="r27am6ym6fttesd3r5rp1akhbntp7m" />   
    <script type="text/javascript">
      {`(function(e, s, t) { t = t || {}, t.id = e, versaTagObj = { $: [], onready: function(e) { this.$.push(e) } }; var n = s.getElementsByTagName("script")[0], r = s.createElement("script"); r.options = t, r.async = !0, r.src = "https://secure-ds.serving-sys.com/SemiCachedScripts/ebOneTag.js?id=" + e, r.options = t, n.parentNode.insertBefore(r, n) })("1073747535", document, { dataLayer:"SizmekDataLayer" });`}
    </script>
  </Helmet>
    <main>
    {children}
    <Scrolling />
    <EnquirePanel {...props} />
    </main>
    </>
  )
}


export default Layout
