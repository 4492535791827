import React, { useEffect } from "react"

const Scrolling = () => {
  
  useEffect(() => { 
    


    const scrollTracker = () => {

      if(window.scrollY > 200) {
        document.querySelector('.scrolling--banner .bg-image')?.classList.add('fade-out');
        document.querySelector('.banner--caption')?.classList.add('fade-out');
      } else {
        document.querySelector('.scrolling--banner .bg-image')?.classList.remove('fade-out');
        document.querySelector('.banner--caption')?.classList.remove('fade-out');
      }
      /*
      if (window.scrollY !== lastScroll) {
        if ((window.scrollY > lastScroll && window.scrollY > 100)) {
          document.querySelector('.masthead').classList.add('fade-out');
        } else {
          document.querySelector('.masthead').classList.remove('fade-out');
        }
      }
      */  


    } 
    
    const interval = setInterval(scrollTracker, 300);
    return () => clearInterval(interval); 
    
  },[]);
  return (
    <></>
  ) 
}

export default Scrolling
 